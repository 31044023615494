// Actions

export const DARK_MODE = "DARK_MODE";

export const projectsSuccess = "projectsSuccess";

export const PROJECTS = "PROJECTS";
export const LOADING = "LOADING";
export const ERROR = "ERROR";
export const FETCH_ITEM_LIST_SUCCESS = "FETCH_ITEM_LIST_SUCCESS";
